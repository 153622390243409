@import '~node_modules/@stobag/mystobag-shared/src/lib/styles/common-styles';

body {
	margin: 0;
	padding: 0;
}

h1, p {
	margin: 0;
}

.drop-down-filters {
	margin-top: 35px !important;
}

// Replace this logic with `font-display: block;` after dropping IE support
*.loading-material-icons .mat-icon {
	display: none;
}

.mat-form-field-appearance-standard .mat-form-field-flex {
	padding-top: 0 !important;
}

.mat-form-field-label {
	color: black;
}

.textlink, .textlink-white {
	padding: 0;
}

.ngx-mat-tel-input-overlay-pane {
    max-height: 430px !important;
}
